<template>
    <div>
        <AppbarFixed :hasBackButton="true" title="Edit Project Order"/>
        <h1 class="mt-4 mb-3">Projects</h1>
        <hr class="mx-3">
        <div class="d-flex flex-row justify-content-between px-4">
            <h6>Display First</h6>

            <h6>Boost Project</h6>
        </div>
        <draggable v-model="myProjects" class="mx-3 my-3" handle=".handle">
            <transition-group>
                <b-list-group-item v-for="item in myProjects" v-bind:key="item.id" class="d-flex align-items-center font-bold justify-content-start my-2 shadow-sm border">
                    <h3 class="handle"><b-icon icon="grip-vertical"></b-icon></h3>
                    <span class="d-flex flex-row justify-content-between font-medium mb-1 mx-1">{{item.title}}</span>
                    <button @click="boostProject(item)" class="boost-button">
                        <h3 >
                            <b-icon v-if="isProjectSelfBoosted(item)" icon="arrow-up-circle-fill" variant="success"></b-icon>
                            <b-icon v-else icon="arrow-up-circle"></b-icon>
                        </h3>
                    </button>

                    <!-- <button class="boost-icon" @click="hi">hi</button> -->
                </b-list-group-item>
            </transition-group>
        </draggable>
        <div class="d-flex flex-row justify-content-between px-4">
            <h6>Display Last</h6>
        </div>
        <hr>
        <b-spinner v-if="loading==true" class="mt-5 mb-3"></b-spinner>
        <b-button v-else class="mt-5" variant="primary" size="lg" @click="saveProjectOrder">Save Order</b-button>
        <div class="bottom-margin-box"></div>
        <BottomNavBar :currentIndex="1" @updateIndex="updateIndex"/>
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import BottomNavBar from '../../components/general/BottomNavBar.vue';
import draggable from 'vuedraggable';
import { mapState } from 'vuex';
import { db, fieldValue } from '../../db';

export default {
    components: {
        AppbarFixed,
        draggable,
        BottomNavBar,
    },
    created() {
        this.myProjects = this.$route.params.projects;
    },
    data() {
        return {
            myProjects: [],
            loading: false,
        }
    },
    computed: {
        ...mapState({
            parsillUser: (state) => state.parsillUser,
        }),
    },
    methods: {
        hi() {
            alert("hi");
        },
        checkMove(evt) {
            alert(evt);
        },
        saveProjectOrder() {
            this.loading = true;
            db.collection("users").doc(this.parsillUser.id).update({
                "projectOrder": this.myProjects.map(e => e.id),
            })
            .then(() => {
                console.log("Successfully updated project order");
                this.$router.go(-1);
            })
            .catch((err) => {
                alert(`Error updating order: ${err}`);
                this.loading = false;
            })
        },
        updateIndex(index) {
            if (index == 4) {
                // means its create project
                this.$router.push("/create/project");
                index = 1;
            } else {
                this.$router.push("/");
            }
            this.$store.commit("updateTabIndex", index);
        },
        isProjectSelfBoosted(project) {
            // return (this.parsillUser.boostedProjects ?? []).includes(project.id);
            return this.parsillUser.selfBoosted == project.id;
        },
        boostProject(project) {
            const userRef = db.collection("users").doc(this.parsillUser.id);
            const projectRef = db.collection("projects").doc(project.id);
            let batch = db.batch();

            // alert("hello");
            
            
            batch.update(userRef, {
                // boostedProjects: fieldValue.arrayUnion(project.id),
                selfBoosted: project.id,
            });

            batch.update(projectRef, {
                boosts: fieldValue.increment(1)
            });

            batch.commit()
            .then(() => {
                console.log("successful boost");
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }
}
</script>

<style scoped>
    .blueFont {
        color: blue;
    }
    
    .greyFont {
        color: gray;
    }

    .font-bold {
        font-weight: bold;
    }

    .font-bold:visited {
        color: white;
    }

    .font-bold:active {
        color: white;
    }

    .center-horizontal {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .label-search {
        font-weight: bold;
        font-size: larger;
    }

    .label-type {
        font-weight: 500;
        font-size: large;
    }

    .font-med {
        font-size: medium;
        font-weight: bold;
    }

    .boost-button {
        margin-left: auto;
        background: transparent;
        border: none;
    }
</style>